@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255; */
}

.reset-all {
  all: initial;
}

.category-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
  display: inline-block;
  border-radius: 10px;
  background: #d9d9d9;
  cursor: pointer;
}

.category-pagination-bullet-active {
  background: #F4AD22 !important;
}

.pagination-custom {
  width: 50% !important;
}

.cp-right-top {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 20% 100%);
}

.cp-right-bottom {
  clip-path: polygon(20% 0, 100% 0, 100% 100%, 0% 100%);
}

.cp-left-top {
  clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
}

.cp-left-bottom {
  clip-path: polygon(0 0, 80% 0, 100% 100%, 0 100%);
}

.cp-donations {
  clip-path: polygon(100% 0, 100% 100%, 50% 95%, 0 100%, 0 0);
}

.sb-height {
  height: calc(100vh - 112px);
}

.min-sb-height {
  min-height: calc(100vh - 112px);
}

@media (prefers-color-scheme: dark) {
  :root {
    /* --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0; */
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
